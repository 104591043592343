import {Injectable} from '@angular/core';
import {Directory, Filesystem} from "@capacitor/filesystem";
import {MediaMatcher} from "@angular/cdk/layout";
import {PlatformEnum} from "../../enums/platform.enum";
import {environment} from "../../../../environments/environment";
import {Capacitor} from "@capacitor/core";
import {HttpClient} from "@angular/common/http";
import {DeviceService} from "../device/device.service";
import {App} from "@capacitor/app";
import {MobileStoreDialogComponent} from "../../components/mobile-store-dialog/mobile-store-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FileOpener} from "@capacitor-community/file-opener";
import {Share} from "@capacitor/share";
import AndroidFilePicker, {
  AndroidPickedFile,
  AndroidPickFileOptions,
  AndroidPickMediaOptions,
  AndroidPickResult
} from "../../interfaces/android-file-picker-plugin.interface";

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  platform: PlatformEnum;

  constructor(
    private mediaMatcher: MediaMatcher,
    private http: HttpClient,
    private deviceService: DeviceService,
    private dialog: MatDialog
  ) {
    this.platform = PlatformEnum[Capacitor.getPlatform()];
  }

  async getMobileMinVersion() {
    const platform = this.deviceService.getPlatformEnum();
    const res = (await this.http.get(`${environment.BASE_PATH}/mobile/min-version/${platform}`).toPromise()) as any;
    return (res as { minVersion: string })?.minVersion;
  }

  async isMobileVersionUpdated() {
    if(!this.deviceService.isMobileDevice()) {
      return true;
    }
    const appInfo = await App.getInfo();
    return appInfo.version >= await this.getMobileMinVersion();
  }

  async downloadAndOpenFileExc(data: string | Blob, fileName: string) {
    let uriToDelete;
    if (data instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = async () => {
        uriToDelete = await this.downloadAndOpenFile(String(reader.result), fileName);
      }
    } else {
      uriToDelete = await this.downloadAndOpenFile(data, fileName);
    }
    if (uriToDelete) {
      await Filesystem.deleteFile({path: uriToDelete});
    }
  }

  async downloadAndOpenFile(base64: string, fileName: string) {
    let uriToDelete: string;
    try {
      const result = await Filesystem.writeFile({
        path: fileName,
        data: <string>base64,
        directory: Directory.Data,
        recursive: true
      });
      await this.openOrShareFile(result.uri, fileName);
      uriToDelete = result.uri;
      console.log('Wrote file', result.uri);
    } catch (e) {
      console.error('Unable to write file', e);
    }
    return uriToDelete;
  }

  async openOrShareFile(uri: string, filename: string) {
    try {
      await FileOpener.open({
        filePath: uri,
        openWithDefault: true,
      });
      console.log("Filed opened", uri);
    } catch (e) {
      console.error('Unable to open file, try sharing...');
      await this.shareFile(uri, filename);
    }
  }

  async shareFile(uri: string, filename: string) {
    try {
      await Share.share({
        title: 'Scarica file',
        text: 'Salva il file ' + filename,
        url: uri,
        dialogTitle: 'Scarica file',
      });
      console.log('File shared', uri);
    } catch (e) {
      console.error('Unable to share', e);
    }
  }

  isMobileDevice() {
    return this.mediaMatcher.matchMedia('(max-width: 800px)').matches;
  }

  getAndroidVersion() {
    const userAgent = navigator.userAgent.toLowerCase();
    const version = userAgent.match(/android\s([0-9\.]*)/i);
    return version[1];
  }

  getMapboxTokenBasedOnDevice() {
    return this.platform == PlatformEnum.ios && environment.production ?
      environment.MAPBOX_KEY_IOS : environment.MAPBOX_KEY;
  }

  private async androidUriToFile(res: AndroidPickResult) {
    let files: File[] = [];
    const resultFiles = JSON.parse(res.result) as AndroidPickedFile[];

    for(const file of resultFiles) {
      const fileName = file.fileName;
      const contentType = file.mimeType;

      const readRes = await Filesystem.readFile({ path: file.uri });
      let byteCharacters;
      if (typeof readRes.data === "string") { // NB: solo per nativo
        byteCharacters = atob(readRes.data);
      }
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: contentType});

      files.push(new File([blob], fileName, { type: contentType }));
    }
    return files;
  }

  async androidPickMedia(options: AndroidPickMediaOptions) {
    const res = await AndroidFilePicker.pickMedia(options);
    return await this.androidUriToFile(res);
  }

  async androidPickFile(options: AndroidPickFileOptions) {
    const res = await AndroidFilePicker.pickFile(options);
    return await this.androidUriToFile(res);
  }

  async openUpdateDialogIfNotUpdated() {
    if(!(await this.isMobileVersionUpdated())) {
      this.dialog.open(MobileStoreDialogComponent, { data: {
          dialogTitle: "Aggiorna Check",
          description: "La versione che stai utilizzando di Check va aggiornata:" +
            "<br/>per farlo scarica quella piu' recente dallo store ufficiale cliccando il bottone 'Vai allo store'",
          storeBtnLabel: "Vai allo store",
          disableClose: true
        }
      });
    }
  }
}
